import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/pageTitle"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

import Img from "gatsby-image"

export const query = graphql`
  query {
    allFile(filter: {sourceInstanceName: {eq: "galleryImages"}}) {
      edges {
        node {
          name
          childImageSharp {
          fluid(maxWidth: 1200, maxHeight: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    }
  }
`

const GalleryPage = ({ data }) => {

  const edges = data.allFile.edges;
  const sortedEdges = edges.sort((a,b) => a.node.name > b.node.name ? 1 : -1 );
  const images = sortedEdges.map(edge => (
    <Col md="6" sm="12" className="my-3">
      <Img fluid={edge.node.childImageSharp.fluid} alt="cat" />
    </Col>
  ))
  return (
    <Layout pageInfo={{ pageName: "Gallery" }}>
      <SEO title="Gallery" />
      <PageTitle title="Gallery" />
      <Container>
        <Row>
          {images}
        </Row>
      </Container>
    </Layout>
  )
}

export default GalleryPage
